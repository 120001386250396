import React from 'react';
import { Link, graphql } from 'gatsby';

import Layout from '../components/Layout';
import Features from '../components/Features';
import logoBig from '../img/logo.png';
import styled, { keyframes } from 'styled-components';
import theme from '../components/theme';
import Sections from '../components/Sections';
import Prices from '../components/Prices';
import Contact from '../components/Contact';
import BackgroundImage from 'gatsby-background-image';

import { BiMouse, BiChevronsDown } from 'react-icons/bi';

const LogoWrap = styled.div`
  background-color: #fff;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
`;

const FullWidthImage = styled(BackgroundImage)`
  background-position: top left;
  background-attachment: fixed;
  height: 100vh;
  position: relative;
`;

const SeparatorImage = styled(BackgroundImage)`
  background-position: top left;
  background-attachment: fixed;
  height: 500px;
  max-height: 50vh;
`;

const MainSection = styled.section`
  background-color: ${theme.colors.primaryDark};
  color: #fff;
`;

const Title = styled.h3`
  &&& {
    color: #fff;
  }
`;

const SectionTitle = styled.h3`
  margin-bottom: 1rem;
`;

const SectionDescription = styled.p`
  font-size: 1.2em;
`;

const SectionHeader = ({ heading, description }) => (
  <>
    <SectionTitle className="has-text-weight-semibold is-size-2">
      {heading}
    </SectionTitle>
    <SectionDescription dangerouslySetInnerHTML={{ __html: description }} />
  </>
);

const downAnim = keyframes`
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(1rem);
  }
  100% {
    transform: translateY(0);
  }
`;

const ScrollDown = styled.a`
  position: absolute;
  bottom: 5.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: 1s 3 ${downAnim} ease-out;
  transition: all 0.2s ease;
  background-color: rgba(255, 255, 255, 0.25);
  border-radius: 50%;
  height: 5rem;
  width: 5rem;
  :hover {
    transform: translateY(1rem);
  }
`;

export const IndexPageTemplate = ({
  image,
  title,
  heading,
  contact,
  cooperate,
  separator,
  description,
  imageBlur,
  main,
  intro,
}) => (
  <div>
    <FullWidthImage
      id="intro"
      Tag="div"
      className="full-width-image margin-top-0"
      fluid={image.childImageSharp.fluid}
    >
      <LogoWrap>
        <img src={logoBig} alt="Avus Tłumaczenia" />
      </LogoWrap>
      <ScrollDown href="#offer">
        <BiMouse size={40} />
        <BiChevronsDown size={30} />
      </ScrollDown>
    </FullWidthImage>
    <MainSection id="offer" className="section section--gradient ">
      <div className="container">
        <div className="section">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <div className="content">
                <div className="columns">
                  <div className="column is-12">
                    <Title className="has-text-weight-semibold is-size-2">
                      {heading}
                    </Title>
                    <p>{description}</p>
                  </div>
                </div>
                <Features gridItems={intro.blurbs} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainSection>
    <div id="translations">
      <section className="section section--gradient white-bg">
        <div className="container">
          <div className="section">
            <div className="columns">
              <div className="column is-10 is-offset-1">
                <SectionHeader {...main} />
                <Sections sections={main.sections} />
              </div>
            </div>
          </div>
        </div>
      </section>

      <MainSection className="section section--gradient">
        <div className="container">
          <div className="section">
            <div className="columns">
              <div className="column is-10 is-offset-1">
                <SectionHeader {...cooperate} />
                <Prices prices={cooperate.prices} />
              </div>
            </div>
          </div>
        </div>
      </MainSection>
    </div>
    <div id="contact">
      <SeparatorImage
        className="full-width-image margin-top-0"
        Tag="div"
        fluid={separator.childImageSharp.fluid}
      />
      <section className="section section--gradient white-bg">
        <div className="container">
          <div className="section">
            <div className="columns">
              <div className="column is-10 is-offset-1">
                <SectionHeader {...contact} />
                <Contact {...contact} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
);

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        imageBlur={frontmatter.imageBlur}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        separator={frontmatter.separator}
        cooperate={frontmatter.cooperate}
        main={frontmatter.main}
        description={frontmatter.description}
        contact={frontmatter.contact}
        intro={frontmatter.intro}
      />
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 85) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        separator {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 85) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        heading
        description
        main {
          heading
          description
          sections {
            title
            items {
              text
            }
          }
        }
        cooperate {
          heading
          description
          prices {
            heading
            disclaimer
            items {
              title
              price
            }
          }
        }
        contact {
          heading
          description
          email
          phone
        }
        intro {
          blurbs {
            image {
              childImageSharp {
                fluid(maxWidth: 240, quality: 85) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            text
          }
        }
      }
    }
  }
`;
