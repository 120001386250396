import React from 'react';
import styled from 'styled-components';
import { FaRegEnvelopeOpen, FaMobileAlt } from 'react-icons/fa';

const Wrap = styled.div`
  margin-top: 3rem;
`;

const Link = styled.a`
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  svg {
    margin-bottom: 1rem;
  }
`;

const Contact = ({ email, phone }) => {
  return (
    <Wrap className="content">
      <div className="columns">
        <div className="column is-6">
          <Link href={`mailto:${email}`}>
            <FaRegEnvelopeOpen size={50} />
            {email}
          </Link>
        </div>
        <div className="column is-6">
          <Link href={`tel:+48${phone.replace(/\ /g, '')}`}>
            <FaMobileAlt size={50} />
            {phone}
          </Link>
        </div>
      </div>
    </Wrap>
  );
};

export default Contact;
