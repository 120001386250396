import React from 'react';
import PropTypes from 'prop-types';
import PreviewCompatibleImage from '../components/PreviewCompatibleImage';
import styled from 'styled-components';

const FeatureImg = styled.div`
  width: 150px;
  display: inline-block;
  margin-bottom: 2rem;
`;

const FeatureText = styled.h4`
  &&& {
    font-weight: 400;
    color: #fff;
    text-align: center;
  }
`;

const FeatureGrid = ({ gridItems }) => (
  <div className="columns is-multiline">
    {gridItems.map((item) => (
      <div key={item.text} className="column is-6">
        <section className="section">
          <div className="has-text-centered">
            <FeatureImg>
              <PreviewCompatibleImage imageInfo={item} />
            </FeatureImg>
          </div>
          <FeatureText dangerouslySetInnerHTML={{ __html: item.text }} />
        </section>
      </div>
    ))}
  </div>
);

FeatureGrid.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      text: PropTypes.string,
    })
  ),
};

export default FeatureGrid;
