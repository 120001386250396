import React from 'react';
import styled from 'styled-components';

const Wrap = styled.div`
  margin-top: 3rem;
`;

const Sections = ({ sections }) => {
  return (
    <Wrap className="content">
      <div className="columns">
        {sections.map((section) => (
          <div className="column is-4" key={section.title}>
            <h4>{section.title}:</h4>
            <ul>
              {section.items.map((item) => (
                <li key={item.text}>{item.text}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </Wrap>
  );
};

export default Sections;
