import React from 'react';
import styled from 'styled-components';

const Wrap = styled.div`
  margin-top: 3rem;
`;

const Heading = styled.h4`
  &&& {
    color: #fff;
    font-size: 2rem;
    margin-top: 2rem;
  }
`;

const Disclaimer = styled.p`
  color: #ddd;
`;

const PricesTable = styled.table`
  margin-bottom: 1rem;
  td {
    padding-top: 0.5rem;
  }
`;

const Price = styled.td`
  padding-left: 1.5rem;
`;

const PriceItem = styled.tr`
  margin-top: 1rem;
`;

const Prices = ({ prices }) => {
  return (
    <div>
      <Heading>{prices.heading}</Heading>
      <PricesTable>
        <tbody>
          {prices.items.map((item) => (
            <PriceItem key={item.title}>
              <td>{item.title}</td>
              <Price>{item.price}</Price>
            </PriceItem>
          ))}
        </tbody>
      </PricesTable>
      <Disclaimer dangerouslySetInnerHTML={{ __html: prices.disclaimer }} />
    </div>
  );
};

export default Prices;
